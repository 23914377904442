import React from 'react'
import '../../styles/LatestYoutubeVideo.scss';

export const LatestYoutubeVideo = ({channelId}) => {
  let randomId = Math.floor(Math.random() * 10) + 1;
  return (
    <div id="latest-video">
      <iframe 
        id="latest-video-iframe" 
        title="Latest Video" 
        src={`https://youtube.com/embed?listType=playlist&list=${channelId}&${randomId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowFullScreen
      >
      </iframe>
    </div>
    )
}

// export default LatestYoutubeVideo