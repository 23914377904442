import React from 'react'
import { motion } from "framer-motion"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Tooltip } from 'react-tooltip'

import '../../styles/DeskMatCard.scss';
import { ExternalLink } from './ExternalLink';

export const DeskMatCard = ({id, title="", desc="", className="", img, links, type, videoPreview}) => {

  return (
      <motion.div id={id}
        className={`${className} deskmat-card`}
        style={{backgroundImage:img}}
        //animate
      >
           {/* <img src={img} alt={title} loading='lazy' /> */}
            <a 
               href={links.large} 
               target='_blank'
               rel='noreferrer'>
               <LazyLoadImage 
                  alt={title}
                  src={img}
                  effect='blur'
                  placeholderSrc={img}
               />
            </a>
            
            <div className='h-100 d-flex flex-column p-2 justify-content-between'>
               <div>
                  <div className='d-flex justify-content-between'>
                     <h3>{title}</h3>
                  </div>
                  <p>{desc}</p>
               </div>
               <div className='d-flex gap-2 justify-content-end'>
                  {(links.large !== "" ) ? <ExternalLink id={id} url={links.large} text='Buy Now' className='deskmat-link-border' /> : ""}
               </div>
            </div>
         
         <Tooltip id={`${id}-preview`} />
      </motion.div>
    )
}

// export default DeskMatCard