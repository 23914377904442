import React, {useState} from 'react'
import Modal from 'react-modal';
import { motion } from "framer-motion"
import { Tooltip } from 'react-tooltip'
import { FaVideo } from "react-icons/fa";
import { SocialIcon } from '../index.js';


import '../../styles/ProductCard.scss';

export const MyProductCard = ({id, title="", desc="", className="", img, url, videoPreview}) => {
  const [videoPreviewIsOpen, setVideoPreviewIsOpen] = useState(false);
  const customVideoPreviewStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
      border: '2px solid transparent !important'

    },
  };
  function openVideoPreview() {
    setVideoPreviewIsOpen(true);
  }

  function afterOpenVideoPreview() {
    // references are now sync'd and can be accessed.
    // console.log("preview open")
  }

  function closeVideoPreview() {
    setVideoPreviewIsOpen(false);
  }

  return (
    <motion.div
      id={id}
      className={`${className} product-card`}
      style={{backgroundImage:img}}
    >
         {/* <img src={img} alt={title} loading='lazy' /> */}
         <img 
          alt={title}
          src={img}
          loading='lazy'
         />
         <a
          className='product-link'
          href={url}
          target='_blank'
          rel='noreferrer'>
            <h4>{title}</h4>
            <p>{desc}</p>
         </a>
         <div id='product-icons'>
          {(videoPreview) 
            ? <div
                className='product-icon'
                data-tooltip-id={`${id}-preview`}
                data-tooltip-content="Preview Product"
                onClick={openVideoPreview}
              >
                <SocialIcon
                  className={"mt-1"}
                  icon={<FaVideo className='FaVideo'></FaVideo>}
                  checkLink={false}
                />
              </div>
            : ""
          }
         </div>
         
         <Modal
          
          id="preview-modal"
          isOpen={videoPreviewIsOpen}
          onAfterOpen={afterOpenVideoPreview}
          onRequestClose={closeVideoPreview}
          style={customVideoPreviewStyles}
          contentLabel="Video Preview Modal"
          ariaHideApp={false}
        >
          <div className='d-flex align-items-center justify-content-center'>
            <iframe 
              id="preview-video-iframe"
              src={videoPreview} 
              title="Preview Product"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowFullScreen
            ></iframe>
          </div>
          
        </Modal>
        
        <Tooltip id={`${id}-preview`} />
    </motion.div>
  )
}

// export default ProductCard