import React from 'react'
import { motion } from "framer-motion"
import { LazyLoadImage } from 'react-lazy-load-image-component';

import '../../styles/WallpaperCard.scss';

export const WallpaperCard = ({id, title="", desc="", className="", img, url}) => {
  return (
      <motion.a id={id}
        className={`${className} wallpaper-card`}
        href={url} 
        target='_blank'
        rel='noreferrer'
        style={{backgroundImage:img}}

        //animate
        whileTap={{scale: 0.90}}
      >
           {/* <img src={img} alt={title} loading='lazy' /> */}
           <LazyLoadImage 
            alt={title}
            src={img}
            effect='blur'
            placeholderSrc={img}
          />
           <div>
              <h3>{title}</h3>
              <p>{desc}</p>
           </div>
      </motion.a>
    )
}

// export default WallpaperCard